<template>
  <a-spin :spinning="spinning">
    <div class="option-bar">
      <div class="bar-left">
        <!-- <span class="option-botton"><a-icon type="left" @click="goBack" />返回</span> -->
        <!-- <span style="margin-left: 10px;"> -->

        <a-button size="small" type="link" @click="goBack">
          <a-icon type="table" />返回协议列表
        </a-button>
        <a-button size="small" :type="'default'" @click="goLastHandler">
          <a-icon type="edit" />返回修改模版
        </a-button>
        <a-button
          size="small"
          :loading="saving"
          :type="'primary'"
          @click="submit"
        >
          <a-icon type="save" />
          {{ l("Save") }}
        </a-button>
        <!-- <a-button :loading="saving" :type="'primary'" ghost @click="submit">
            <a-icon type="save" />
            {{ l('Save') }}
          </a-button> -->
        <!-- </span> -->
      </div>
      <!--<div class="bar-right">
        <span class="option-botton"><a-icon type="cloud-download" />下载</span>
        <span class="option-botton"><a-icon type="printer" />打印</span>
      </div>-->
    </div>
    <div class="bax">
      <div class="templete-manage" ref="tm">
        <!-- :style="{minWidth: editor ? '350px' : '0'}" -->
        <div class="tm-l drag-vb" style="z-index: 10" id="tml">
          <draggable
            class="dragArea"
            :id="'draggable' + (index + 1)"
            v-for="(item, index) in pdf_pages"
            :key="'cc' + index"
            tag="div"
            :move="onMove"
          >
            <!-- </draggable> -->
          </draggable>
        </div>

        <div class="tm-c tm-rw" id="tmRW1">
          <div class="pw">
            <div class="seal-title title">将印章拖拽到右边合同当中</div>
            <div class="b-w bw1" id="scrollbox">
              <draggable
                :list="seallist"
                :group="{
                  name: 'seal',
                  put: false,
                  pull: 'clone',
                }"
                @change="change"
                @start="start"
                @end="end"
                :sort="false"
                :move="onMove"
                :clone="cloneSeal"
                tag="div"
                handle=".seal-box"
              >
                <div
                  class="re-box isSignbox"
                  v-for="item in seallist"
                  :key="item.id"
                  :id="'sealboxhover' + item.id"
                >
                  <!-- https://static.dianmi-north.cn/cesseal.png -->
                  <div
                    v-if="item.type == 1"
                    :id="'sealid' + item.id"
                    class="seal-box"
                    :data-outid="'sealboxhover' + item.id"
                  >
                    <span class="seal-name">{{ item.name }}</span>
                    <!-- <a-icon
                      type="close-circle"
                      class="isSignbox"
                      :data-outid="'sealboxhover' + item.id"
                    /> -->
                    <img
                      v-if="item.pictureUrls != ''"
                      :src="uploadBaseUrl + item.pictureUrls"
                      class="isSignbox"
                      :data-outid="'sealboxhover' + item.id"
                    />
                    <span
                      v-if="item.pictureUrls == ''"
                      class="isSignbox personal-sign"
                      :data-outid="'sealboxhover' + item.id"
                      >{{ item.name }}</span
                    >
                  </div>
                  <div
                    v-if="item.type != 1"
                    :id="'sealid' + item.id"
                    class="seal-box"
                    :data-outid="'sealboxhover' + item.id"
                  >
                    <!-- <a-icon
                      @click.stop="delSeal(item, index)"
                      type="close-circle"
                      class="isSignbox"
                      :data-outid="'sealboxhover' + item.id"
                    /> -->
                    <span
                      class="personal-sign isSignbox"
                      :data-outid="'sealboxhover' + item.id"
                      >{{ item.name }}</span
                    >
                    <!-- https://static.dianmi-north.cn/cesseal.png -->
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="tm-r tm-rw" id="tmRW2">
          <div class="pw">
            <div class="title seal-title">已拉入签章数据</div>
            <div class="b-w bw2">
              <ul
                class="la-in"
                v-for="(sItem, sIndex) in displaySealList"
                :key="sIndex"
              >
                <li>
                  <span>{{ sItem.name }}</span>
                </li>
                <li>
                  <span>签署顺序：</span>
                  <a-input
                    size="small"
                    style="width: 150px"
                    default-value="1"
                    placeholder=""
                    allowClear
                    type="number"
                    v-model="sItem.sort"
                    @blur="sortChange(sItem)"
                  />
                </li>

                <li>
                  <span>签署类型：</span>
                  <a-select
                    allowClear
                    size="small"
                    style="width: 150px"
                    @select="signTypeSelect($event, sItem, sIndex)"
                    placeholder="请选择"
                    :default-value="sItem.signType"
                  >
                    <a-select-option value="0">手动签署</a-select-option>
                    <a-select-option value="1">自动签署</a-select-option>
                    <a-select-option value="2"
                      >手动签章(不校验)</a-select-option
                    >
                  </a-select>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import draggable from "vuedraggable";
import { AppConsts } from "@/abpPro/AppConsts";
let PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");
let hoverX = 0;
let hoverY = 0;
let distanceX = 0;
let distanceY = 0;
export default {
  mixins: [AppComponentBase],
  components: {
    draggable,
  },
  data() {
    return {
      spinning: false,
      uploadBaseUrl: AppConsts.uploadBaseUrl,
      oSeallist: [],
      clItem: {},
      // clPos: {},
      seallist: [
        // {
        //   id: "40c6c496-3cb7-4773-4292-08d8c35033e1",
        //   isDefault: false,
        //   name: "默认印章",
        //   number: "1",
        //   organizationUnitId: 0,
        //   picUrl: "http://192.168.25.210:32110undefined",
        //   pictureUrls: "/static/abpProd/2021/01/28/16118128470812.png",
        //   sealType: "Fdd",
        //   sort: "1",
        //   status: 0,
        //   type: 1,
        // }
        // {
        //   url: "https://static.dianmi-north.cn/cesseal.png",
        //   type: 1,
        //   id: "seal1",
        // },
        // { type: 2, id: "persao1" },
      ],
      fixSeal: [
        {
          id: "singer-",
          name: "个人签名",
          type: 3,
          sort: "",
        },
        {
          id: "agent-1",
          name: "代理人签名1",
          type: 4,
          sort: "",
        },
        {
          id: "agent-2",
          name: "代理人签名2",
          type: 4,
          sort: "",
        },
        {
          id: "agent-3",
          name: "代理人签名3",
          type: 4,
          sort: "",
        },
        {
          id: "agent-4",
          name: "代理人签名4",
          type: 4,
          sort: "",
        },
        {
          id: "agent-5",
          name: "代理人签名5",
          type: 4,
          sort: "",
        },
        {
          id: "agent-6",
          name: "代理人签名6",
          type: 4,
          sort: "",
        },
        {
          id: "agent-7",
          name: "代理人签名7",
          type: 4,
          sort: "",
        },
        {
          id: "agent-8",
          name: "代理人签名8",
          type: 4,
          sort: "",
        },
      ],
      fieldList: [],
      submitSealList: [],
      displaySealList: [],
      htmlcontext: "",
      pdf_scale: 1.3, //pdf放大系数
      pdf_pages: [],
      pdf_div_width: "",
      pdf_src: null,
      scrolltop: 0,
      signTypeOpt: [
        { label: "手动签署", value: "0" },
        { label: "自动签署", value: "1" },
        { label: "手动签章（不校验）", value: "2" },
      ],
      singerIndex: 0,
      // agentIndex: 0,
      signingMethods: [],
    };
  },
  computed: {
    elTMC() {
      return this.$el.querySelector(".tm-c");
    },
    elBW1() {
      return this.$el.querySelector(".bw1");
    },
    elBW2() {
      return this.$el.querySelector(".bw2");
    },
    elTMR() {
      return this.$el.querySelector(".tm-r");
    },
    // elTMSUB() {
    //   return this.$el.querySelector(".tm-sub");
    // },
    maxTop() {
      if (this.pdf_pages > 0) {
        let h = document.getElementById("draggable1").offsetHeight;
        return h - 160;
      } else {
        return 945;
      }
    },
    maxLeft() {
      if (this.pdf_pages > 0) {
        let w = document.getElementById("draggable1").offsetWidth;
        return w - 160;
      } else {
        return 600;
      }
    },
  },
  created() {
    // document.getElementsByTagName("body")[0].style.overflow = "hidden";
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    console.log(this.$route);
    if (this.$route.query.type == 1) {
      this.fixSeal = [];
    }
    this._getSealListByProjectId();
  },
  async mounted() {
    window.addEventListener("scroll", this.scrollevent);
    let that = this;
    this.$nextTick(() => {
      let elem = document.getElementsByClassName("seal-box");
      console.log(elem);
      if (elem.length > 0) {
        for (var i = 0; i < elem.length; i++) {
          // console.log("ec", elem[i]);
          this.bindEvent(elem[i], "mousedown", function (e) {
            // console.log(e);
            hoverX = e.offsetX + 20;
            hoverY = e.offsetY + 20;
          });
        }
      }
    });
    this.$nextTick(() => {
      this.initRightTableHeight();
    });
  },
  methods: {
    initRightTableHeight() {
      // let _tmc = this.$el.querySelector(".tm-c");
      // let _tmr = this.$el.querySelector(".tm-r");
      // console.log(_tmc)
      // console.log(_tmr)
      const height = window.innerHeight - 50;
      this.elTMC.style.height = height + "px";
      this.elBW1.style.height = height - 45 + "px";
      this.elBW2.style.height = height - 45 + "px";
      this.elTMR.style.height = height + "px";
    },
    scrollevent() {
      this.scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // document
      //   .getElementById("tmRW1")
      //   .setAttribute("style", "margin-top:" + (this.scrolltop) + "px");
      this.elTMC.style.marginTop = this.scrolltop + "px";
      this.elTMR.style.marginTop = this.scrolltop + "px";
      // this.elTMSUB.style.marginTop = this.scrollTop + "px"
    },
    _renderPage(num) {
      //渲染pdf页
      const that = this;
      this.pdfDoc.getPage(num).then((page) => {
        let doc = document.getElementById("draggable" + num);
        let hoverBox = document.createElement("div");
        let numbox = document.createElement("div");
        numbox.innerHTML = "第" + num + "页";
        numbox.setAttribute("style", "position: absolute;right:10px;top:10px");
        hoverBox.setAttribute("id", "canvas-box" + num);
        hoverBox.setAttribute("class", "canvas-box");
        hoverBox.setAttribute("data-num", num);
        let canvas = document.createElement("canvas");
        canvas.setAttribute("class", "pdf-canvas");
        canvas.setAttribute("id", "pdf-canvas" + num);

        let drapbox = document.createElement("pdfcanves");
        let ctx = canvas.getContext("2d");
        let dpr = window.devicePixelRatio || 1;
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;
        let ratio = 1;
        // console.log('ratio:', ratio);
        let viewport = page.getViewport(this.pdf_scale);
        // console.log('viewport:', viewport);
        canvas.width = 595 * this.pdf_scale * ratio;
        hoverBox.setAttribute(
          "style",
          "border: 1px #dad4d4 dashed;width:" +
            (canvas.width + 2) +
            // "px;position: relative;margin-bottom:10px;"
            "px;position: relative"
        );
        canvas.height = 842 * this.pdf_scale * ratio;
        canvas.style.width = 595 * this.pdf_scale + "px";
        that.pdf_div_width = 595 * this.pdf_scale + "px";
        canvas.style.height = 842 * this.pdf_scale + "px";
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
        hoverBox.appendChild(canvas);
        hoverBox.appendChild(numbox);
        drapbox.appendChild(hoverBox);
        doc.appendChild(hoverBox);
        if (this.pdf_pages > num) {
          this._renderPage(num + 1);
        } else {
          this.spinning = false;
          this.$nextTick(() => {
            this.renderCloneSealList(this.signingMethods);
          });
        }
      });
    },
    async preMakePdf() {
      this.spinning = true;
      let res = await this.commonService.post({
        url: `/api/services/app/AgreementTemplate/PreMakePdf`,
        params: {
          content: this.htmlcontext,
          generalFieldsOfAgreementContentIds: this.fieldList,
        },
      });
      const loadingTask = PDFJS.getDocument(`${AppConsts.uploadBaseUrl}${res}`);
      //let loadingTask = PDFJS.getDocument(url);
      loadingTask.promise.then((pdf) => {
        console.log("得到的pdf数据:", pdf);

        this.pdfDoc = pdf;
        this.pdf_pages = this.pdfDoc.numPages;
        console.log("this.pdf_pages:", this.pdf_pages);
        //debugger
        this.$nextTick(() => {
          this._renderPage(1);
        });
      });
    },
    cloneSeal(o) {
      console.log("cloneSeal:", o);
      this.clItem = { ...o };
      return { ...o };
    },
    delSeal(e) {
      let _this = this;
      this.$confirm({
        title: "确认删除印章?",
        content: "",
        onOk() {
          let _p = e.target.parentNode;
          let _pp = e.target.parentNode.parentNode;
          let _ppId = e.target.parentNode.parentNode.id;
          console.log(_ppId);
          const _id = _ppId.split("sealboxhover")[1];
          const _arr = _this.submitSealList.filter((v) => v.cloneId !== _ppId);
          const __arr = _arr.filter((v) => v.id === _id);
          _this.submitSealList = _arr;
          if (!__arr.length) {
            _this.displaySealList = _arr.filter((v) => v.id !== _id);
          }
          _pp.removeChild(e.target.parentNode);
          _this.$notification["success"]({
            message: _this.l("SuccessfullyDeleted"),
          });
        },
        onCancel() {},
      });
    },
    change(evt) {
      console.log(evt, "change...");
    },
    //start ,end ,add,update, sort, remove 得到的都差不多
    start(evt) {
      // this.drag = true;
      console.log("start事件", evt);
      //选取时，隐藏其他印章
      let allbox = document
        .getElementById("tml")
        .getElementsByClassName("seal-box");
      for (let i = 0; i < allbox.length; i++) {
        allbox[i].setAttribute("style", "display:none");
      }
      // console.log(allbox);
    },
    bindEvent(dom, eventName, listener) {
      dom.addEventListener(eventName, listener);
    },
    removeEvent(dom, eventName, listener) {
      dom.removeEventListener(eventName, listener);
    },
    end(evt) {
      console.log("end事件", evt);
      let allbox = document
        .getElementById("tml")
        .getElementsByClassName("seal-box");
      for (let i = 0; i < allbox.length; i++) {
        allbox[i].setAttribute("style", "");
      }
      console.log(
        "evt.originalEvent.srcElement.className:",
        evt.originalEvent.srcElement.className
      );

      if (
        evt.originalEvent.srcElement.parentElement.parentElement.id.indexOf(
          "draggable"
        ) > -1
      ) {
        let cloneEle = evt.clone;
        let idName = this.returnCloneIdName(cloneEle);
        cloneEle.id = idName;
        cloneEle.setAttribute(
          "style",
          "position: absolute;left:" +
            (evt.originalEvent.offsetX - hoverX - 80) +
            "px;top:" +
            (evt.originalEvent.offsetY - hoverY - 80) +
            "px;z-index: 10;"
        );
        cloneEle.classList.add("clone-ele");
        cloneEle.addEventListener("dragend", this.dragEnd, false);
        cloneEle.addEventListener("drag", this.drag, false);
        cloneEle.addEventListener("dragstart", this.dragStart, false);
        let _this = this;
        let _span = document.createElement("span");
        _span.innerHTML = "ⓧ";
        _span.style.cssText =
          "color: red;cursor: pointer;position: absolute;width: 18px;height: 18px;font-size: 18px;top: -14px;right: -10px;z-index: 40;";
        _span.addEventListener(
          "click",
          (e) => {
            _this.delSeal(e);
          },
          false
        );
        console.log(cloneEle.childNodes);

        for (let i = 0; i < cloneEle.childNodes.length; i++) {
          if (cloneEle.childNodes[i].id) {
            cloneEle.childNodes[i].appendChild(_span);
            break;
          }
        }

        let elect = document.getElementById(
          evt.originalEvent.srcElement.parentElement.parentElement.id
        );
        elect.appendChild(cloneEle);

        let obj = { ...this.clItem };
        obj.signType = undefined;
        obj.cloneId = idName;
        obj.delShow = true;
        if (obj.type == 3) {
          let singleType = this.displaySealList.filter((v) => v.type === 3);
          if (singleType.length == 0) {
            obj.id = `${obj.id}`;
            this.singerIndex++;
            this.displaySealList.push(obj);
          } else {
            obj.sort = singleType[0].sort;
            obj.signType = singleType[0].signType;
          }
        } else if (obj.type == 4) {
          let arr = this.displaySealList.filter((v) => v.id === obj.id);
          if (!arr.length) {
            this.displaySealList.push(obj);
          } else {
            //重写一下sort,signtype
            obj.sort = arr[0].sort;
            obj.signType = arr[0].signType;
          }
        } else {
          let arr = this.displaySealList.filter((v) => v.id === obj.id);
          if (!arr.length) {
            this.displaySealList.push(obj);
          } else {
            //重写一下sort
            obj.sort = arr[0].sort;
            obj.signType = arr[0].signType;
          }
        }
        this.displaySealList = this.displaySealList.sort((a, b) => {
          return Number(a.sort) - Number(b.sort);
        });
        this.submitSealList.push(obj);
        console.log(this.submitSealList);
      }
    },
    onMove(evt, originalEvent) {
      console.log("move事件", evt);
      console.log("originalEvent:", originalEvent); //鼠标位置
    },
    dragEnd(e) {
      // console.log(e, e.offsetX, e.offsetY)
      let ele = document.getElementById(e.srcElement.id);
      console.log("offsetTop::::", ele.offsetTop);
      console.log("offsetLeft::::", ele.offsetLeft);

      let allbox = document
        .getElementById("tml")
        .getElementsByClassName("seal-box");
      for (let i = 0; i < allbox.length; i++) {
        allbox[i].classList.remove("hide");
      }
      //获取实时鼠标位置
      let x = e.clientX;
      let y = e.clientY;
      // 计算出ele的坐标
      let left = x - distanceX;
      let top = y - distanceY;

      if (left >= this.maxLeft) {
        //当超过右边界
        ele.style.left = this.maxLeft + "px";
      } else if (left < 0) {
        //当超过左边界
        ele.style.left = "0" + "px";
      } else {
        ele.style.left = left + "px";
      }
      if (top >= this.maxTop) {
        //当超过右边界
        ele.style.top = this.maxTop + "px";
      } else if (top < 0) {
        //当超过左边界
        ele.style.top = "0" + "px";
      } else {
        ele.style.top = top + "px";
      }
    },
    drag(e) {
      // let ele = document.getElementById(e.srcElement.id)
      // // console.log(e, e.offsetX, e.offsetY)
      // if (ele.offsetTop > 600) {
      //   ele.style.top = 600 + 'px'
      // } else if (ele.offsetTop < 0) {
      //   ele.style.top = 0 + 'px'
      // } else {
      //   ele.style.top = e.srcElement.offsetTop + e.offsetY - 70 + 'px'
      // }
      // if (ele.offsetLeft > 945) {
      //   ele.style.left = 945 + 'px'
      // } else if (ele.offsetLeft < 0) {
      //   ele.style.left = 0 + 'px'
      // } else {
      //   ele.style.left = e.srcElement.offsetLeft + e.offsetX - 70 + 'px'
      // }
      // console.log(item)
      // console.log(index)
      // this.clPos = {
      //   top: e.offsetY,
      //   left: e.offsetX,
      // }
    },
    dragStart(e) {
      console.log(e);
      let allbox = document
        .getElementById("tml")
        .getElementsByClassName("seal-box");
      for (let i = 0; i < allbox.length; i++) {
        if (e.srcElement.id !== allbox[i].parentNode.id) {
          allbox[i].classList.add("hide");
        }
      }
      //获取鼠标位置
      const x = e.clientX;
      const y = e.clientY;
      //获取draggable的位置
      const offsetX = e.target.offsetLeft;
      const offsetY = e.target.offsetTop;
      // console.log(offsetX, offsetY);
      //保存鼠标悬停处与draggable边界的距离(是全局变量)
      distanceX = x - offsetX;
      distanceY = y - offsetY;
    },
    sortChange(item) {
      this.displaySealList = this.displaySealList.sort((a, b) => {
        return Number(a.sort) - Number(b.sort);
      });
      //将其他相同key的签署类型改为一致
      this.submitSealList = this.submitSealList.map((value) => {
        if (value.id == item.id) {
          value.sort = item.sort;
        }
        return value;
      });
    },
    signTypeSelect(v, item, index) {
      console.log(v, item, index, this.submitSealList);
      item.signType = v;
      //将其他相同key的排序改为一致
      this.submitSealList = this.submitSealList.map((value) => {
        if (value.id == item.id) {
          value.signType = item.signType;
        }
        return value;
      });
      //console.log(this.submitSealList);
    },
    // 根据id获取模版信息
    async _agreementTemplateGetEditById(id) {
      this.spinning = true;

      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementTemplate/GetEditById",
          params: { id: id },
        });
        this.htmlcontext = res.content;

        if (res.signingMethods.length) {
          this.signingMethods = res.signingMethods;
        }
        this.fieldList = res.generalFieldsOfAgreementContentIds;
        this.spinning = false;
        this.preMakePdf();
      } catch (error) {
        this.spinning = false;
        console.log(error);
      }
    },
    // 渲染已存在seal
    renderCloneSealList(signingMethods) {
      signingMethods.map((item) => {
        const pdfIdName = `draggable${item.pageNum}`;

        let _domIDname;
        let _keyWord = item.keyWord;

        if (item.flagSignerType == 1) {
        } else if (item.flagSignerType == 3) {
          _keyWord = item.keyWord.split("-")[0] + "-";
        } else if (item.flagSignerType == 4) {
        }

        _domIDname = `sealboxhover${_keyWord}`;

        // console.log('_domIDname::::', _domIDname)
        let sealDom = document.getElementById(_domIDname);

        if (sealDom) {
          let cloneEle = sealDom.cloneNode(true);

          const cloneId = this.returnCloneIdName(cloneEle);

          cloneEle.id = cloneId;
          cloneEle.setAttribute(
            "style",
            "position: absolute;left:" +
              item.offsetX +
              "px;top:" +
              item.offsetY +
              "px;z-index: 10;"
          );
          // cloneEle.setAttribute("data-pageid", item.keyWord);
          cloneEle.classList.add("clone-ele");
          cloneEle.addEventListener("dragend", this.dragEnd, false);
          cloneEle.addEventListener("drag", this.drag, false);
          cloneEle.addEventListener("dragstart", this.dragStart, false);

          // console.log('cloneEle:', cloneEle)

          let targetEle = document.getElementById(pdfIdName);

          // console.log('targetEle:', targetEle)

          let _this = this;
          let _span = document.createElement("span");
          _span.innerHTML = "ⓧ";
          _span.style.cssText =
            "color: red;cursor: pointer;position: absolute;width: 18px;height: 18px;font-size: 18px;top: -14px;right: -10px;z-index: 40;";
          _span.addEventListener(
            "click",
            (e) => {
              _this.delSeal(e);
            },
            false
          );
          // console.log(cloneEle.childNodes)
          for (let i = 0; i < cloneEle.childNodes.length; i++) {
            if (cloneEle.childNodes[i].id) {
              cloneEle.childNodes[i].appendChild(_span);
              break;
            }
          }

          targetEle.appendChild(cloneEle);

          const seal = this.seallist.filter((v) => v.id == _keyWord)[0];

          let obj = {
            cloneId: cloneId,
            delShow: true,
            id: item.keyWord,
            name: seal.name,
            picUrl: `${this.uploadBaseUrl}${seal.pictureUrls}`,
            pictureUrls: seal.pictureUrls,
            signType: `${item.signMethod}`,
            sort: `${item.sort}`,
            type: Number(item.flagSignerType),
          };

          if (obj.type == 3) {
            let singleType = this.displaySealList.filter((v) => v.type === 3);
            if (singleType.length == 0) {
              obj.id = `${obj.id}`;
              this.singerIndex++;
              this.displaySealList.push(obj);
            } else {
              obj.sort = singleType[0].sort;
              obj.signType = singleType[0].signType;
            }
          } else if (obj.type == 4) {
            let arr = this.displaySealList.filter((v) => v.id === obj.id);
            if (arr.length == 0) {
              this.displaySealList.push(obj);
            } else {
              //重写一下sort
              obj.sort = arr[0].sort;
              obj.signType = arr[0].signType;
            }
          } else {
            let arr = this.displaySealList.filter((v) => v.id === obj.id);
            if (arr.length == 0) {
              this.displaySealList.push(obj);
            } else {
              //重写一下sort
              obj.sort = arr[0].sort;
              obj.signType = arr[0].signType;
            }
          }
          this.displaySealList = this.displaySealList.sort((a, b) => {
            return Number(a.sort) - Number(b.sort);
          });
          this.submitSealList.push(obj);
        }
      });
    },
    // 获取印章列表
    async _getSealListByProjectId() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: `/api/services/app/UnnaturalPersonProjectAgreement/GetSealListByProjectId`,
          params: {
            projectId: this.$route.query.projectId,
            agreementGroupType: this.$route.query.type,
          },
        });
        if (res && res.length) {
          this.seallist = res.map((v) => {
            return {
              ...v,
              type: 1,
              picUrl: `${this.uploadBaseUrl}${v.pictureUrls}`,
            };
          });
          this.seallist = this.seallist.concat(this.fixSeal);
          this.oSeallist = [...this.seallist];
          console.log(this.seallist);
        }
        this.spinning = false;
        this.$nextTick(() => {
          this._agreementTemplateGetEditById(this.$route.params.id);
        });
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    submit() {
      let _this = this;
      this.$confirm({
        title: "确认保存?",
        content: "",
        onOk() {
          _this._saveProjectTemplateSignMethonsV2();
        },
        onCancel() {},
      });
    },
    async _saveProjectTemplateSignMethonsV2() {
      this.spinning = true;
      this.saving = true;
      try {
        let signMethods = [];
        this.submitSealList.map((item) => {
          let _cldom = document.getElementById(item.cloneId);
          let _parentId = _cldom.parentNode.id;
          let _pageNum = _parentId.split("draggable")[1];
          // console.log(_cldom.offsetTop)
          // console.log(_cldom.offsetLeft)
          // console.log(_cldom.parentNode)
          // console.log(_cldom.parentNode.id)

          const obj = {
            sealName: item.name,
            sort: item.sort,
            keyWord: item.id,
            offsetX: _cldom.offsetLeft,
            offsetY: _cldom.offsetTop,
            pageNum: _pageNum,
            signerType: item.type,
            organizationSealId: item.type == 1 ? item.id : null,
            signMethod: item.signType,
          };
          ///如果是默认章，则还原ID为Null
          if (
            obj.organizationSealId == "00000000-0000-0000-0000-000000000000"
          ) {
            obj.organizationSealId = null;
          }
          signMethods.push(obj);
        });

        const opts = {
          projectId: this.$route.query.projectId,
          templateId: this.$route.params.id,
          signMethods: signMethods,
        };
        console.log(opts);
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/SaveProjectTemplateSignMethonsV2",
          params: opts,
        });
        this.notify.success(this.l("SavedSuccessfully"));
        this.spinning = false;
        this.saving = false;
        setTimeout(() => {
          this.goBack();
        }, 1000);
      } catch (error) {
        this.spinning = false;
        this.saving = false;
        console.log(error);
      }
    },
    goBack() {
      this.$router.push({
        path: `/app/unnaturalperson/agreementtypev2`,
      });
    },
    goLastHandler() {
      //this.$router.back();
      ///5dfb0e56-0bb4-4fe7-908e-d1019ac43f3e?type=2&projectId=0E290F30-5CAB-4200-8F12-C08EB0597C7A&projectName=春江花月夜项目
      window.location.href = `/#/app/fs/addProjectContract/${this.$route.params.id}?type=${this.$route.query.type}&projectId=${this.$route.query.projectId}&projectName=${this.$route.query.projectName}`;
    },
    returnCloneIdName(cloneEle) {
      let idName = "";
      for (let g = 1; g < 100; g++) {
        let _idName = `${g}${cloneEle.id}`;
        let _ele = document.getElementById(_idName);
        if (!_ele) {
          idName = _idName;
          break;
        }
      }
      return idName;
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";
.option-botton {
  cursor: pointer;
  margin: 0 10px;
}
.bax {
  padding-top: 40px;
}
.la-in {
  padding: 10px;
  border: 1px #f2f2f2 dashed;
  margin-top: 5px;
  margin-bottom: 10px;
}
.seal-box {
  position: relative;
  text-align: center;
  width: 160px;
  padding: 20px;
  border: 1px red dashed;
  margin-bottom: 10px;
  margin-top: 5px;
  // margin: 0 auto;
  // margin-bottom: 10px;
  cursor: pointer;
  img {
    width: 120px;
  }
  i {
    color: red;
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: -7px;
  }
  .close-icon {
    color: red;
    cursor: pointer;
    position: absolute;
    width: 18px;
    height: 18px;
    font-size: 18px;
    top: -14px;
    right: -10px;
  }
  .seal-name {
    cursor: pointer;
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 12px;
    color: red;
    background: #fff;
  }
}

.personal-sign {
  height: 120px;
  display: block;
  line-height: 120px;
  color: red;
  font-weight: bolder;
}
.drag-vb {
  position: relative;
}
.seal-title {
  padding: 0 10px;
  line-height: 30px;
  font-size: 13px;
  font-weight: bold;
  #borderBottom-1px(@color-border-gray);
}
.option-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  width: 100%;
  display: block;
  border-bottom: solid 1px #f2f2f2;
  box-shadow: 0px 2px 5px @color-border-gray;
  background-color: #fff;
  z-index: 30;

  .bar-left {
    float: left;
    line-height: 40px;
    margin-left: 20px;
  }
  .bar-right {
    float: right;
    line-height: 40px;
    margin-right: 20px;
  }
}
.a4-box {
  height: 842px;
  width: 595px;
}
.dragArea {
  position: relative;
  .cl-seal {
    position: absolute;
    left: 0;
    top: 0;
    // width: 100%;
    // height: 100%;
    z-index: 20;
  }
}
.pdf-canvas {
  position: relative;
}
.re-box {
  position: relative;
}
#hideHtml {
  //margin: 0 ;
  //padding: 0;
  //width: 795px;

  // margin-right: 260px;
  text-align: center;
  /* change the margins as you want them to be. */
}

.templete-manage {
  .iw-l {
    margin-bottom: 10px;
  }
  .b-w {
    position: relative;
    padding: 0 10px 10px;
    overflow-y: scroll;
    // margin-top: 20px;
    // border: 1px #dad4d4 dashed;
  }

  // min-height: 600px;
  position: relative;
  #flex;
  width: 100%;
  // min-width: 1558px;

  .tm-l {
    // position: absolute;
    // left: 0;
    // width: 72%;
    // padding: 0 10px 0 20px;
    padding: 10px 0 0 20px;
    // margin: 5px;
    min-width: 775px;
    // #flex1;
    .cp-t-w {
      margin-bottom: 20px;
      margin-top: 20px;
      border: #f2f2f2 1px solid;
      padding: 10px;
    }
    .t-w {
      // background-color: #fff;
    }
    .content-w {
      width: 100%;
      // background-color: #fcf;
      // max-height: 400px;
    }
  }

  .tm-rw {
    .pw {
      border: 1px #dad4d4 dashed;
      height: 100%;
      overflow: hidden;
    }
    padding: 10px 0 0 10px;
    // position: absolute;
    // top: 0;
    // right: 0;
    // height: 100%;
    // height: 800px;
    // min-width: 250px;
    // background-color: @color-white;
    .item-w {
      #flex;
      #flex-vc;
      margin-bottom: 10px;
      .iw-l {
        margin-bottom: 10px;
        #tar;
        padding-right: 5px;
        width: 30%;
        font-size: 12px;
      }
      .iw-r {
        width: 70%;
      }
    }
    .day-input {
      // margin-top: 3px;
      margin-bottom: 10px;
    }
    .desc-w {
      padding: 10px;
      border: #f2f2f2 1px solid;
      .title {
        #fs14;
      }
      .tips {
        margin-bottom: 3px;
        font-size: 12px;
        color: #80848f;
      }
    }
    .fields-w {
      .title {
        color: @color-black;
        padding: 2px 0;
        font-size: 12px;
        font-weight: bolder;
      }
      ul {
        #flex;
        #flex-hw;
        #flex-vc;
        //padding-left: 0;
        // margin-bottom: 0;
        li {
          list-style: none;
          width: 31%;
          margin-right: 1.5%;
          margin-bottom: 1%;
          span {
            #tac;
            #dib;
            padding: 2px 5px;
            #w100;
            #h100;
            border: 1px solid @color-blue;
            #border-radius(3px);
            font-size: 12px;
            background-color: @color-white;
            color: @color-blue;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &:hover {
              background-color: @color-blue;
              color: @color-white;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .tm-r {
    width: 300px;
    .bw2 {
      ul {
        li {
          #flex;
          #flex-vc;
          margin-bottom: 4px;
          font-size: 13px;
        }
        li:nth-of-type(1) {
          span {
            #fw;
            font-size: 15px;
          }
        }
      }
    }
  }
  .tm-c {
    width: 200px;
    .draggable-w {
      overflow-y: scroll;
    }
  }
}
.hide {
  display: none;
}
div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
}
div::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 72%);
  // border-radius: 3px;
}
div::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-thumb:active {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-track {
  box-shadow: 0 0 0 gray inset;
}
</style>